import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import secureLocalStorage from "react-secure-storage";
import CryptoJS from "crypto-js";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiIpPort: process.env.REACT_APP_API_ENDPOINT,
  apiBaseUrl: process.env.REACT_APP_EMPLOYEEONBOARDING_APP_URL,
  apiBaseUrl2: process.env.REACT_APP_TASKTRACKER_APP_URL,
  apiBaseUrlGp: process.env.REACT_APP_GRIEVANCE_APP_URL,
  apiBaseUrlResource: process.env.REACT_APP_RESOURCEMASTER_APP_URL,
  googleClientId:
    "784589856430-6doef1vja61cm270fn6sd4fm1mddjbuu.apps.googleusercontent.com",
};

export const errorToast = (res) => {
  toast.error(res, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    closeButton: false,
  });
};

export const successToast = (res) => {
  toast.success(res, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    closeButton: false,
  });
};

export const checkIfEmpty = (value1, value2) => {
  if (value1) {
    return value2;
  } else {
    return "";
  }
};

export const accessRoles = {
  superAdmin: 1,
  manager: 2,
  lead: 3,
  recruiter: 4,
  sales: 15,
  hrCoordinator: 16,
  hrOperation: 17,
};

export const localStorageSetItem = (key, value) => {
  secureLocalStorage.setItem(key, value);
};

export const localStorageGetItem = (key) => {
  return secureLocalStorage?.getItem(key);
};

export const employeeId = secureLocalStorage.getItem("employeeId");
export const employeeName = secureLocalStorage.getItem("name");
export const employeeRole = secureLocalStorage.getItem("roles");

export const getLastThreeMonths = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // Returns 0 for January, 1 for February, ..., 11 for December

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const lastThreeMonths = [
    { id: currentMonth - 2, label: months[(currentMonth - 2 + 12) % 12] },
    { id: currentMonth - 1, label: months[(currentMonth - 1 + 12) % 12] },
    { id: currentMonth, label: `${months[currentMonth]} - Current Month` },
  ];

  return lastThreeMonths;
};

export const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },

  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const currentAndPrevMonths = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // Returns 0 for January, 1 for February, ..., 11 for December

  return months.slice(0, currentMonth + 1);
};

//ATS menu Code redirect
export const menuRedirect = [
  {
    elementCode: "277Z4054P81578MD",
    modelName: "Position Management",
    prevData: {
      page: "positionList",
      sidebar: "positionList",
      page1: "positionList",
      active: "positionList",
    },
    nextScreen: "positionList",
  },
  {
    elementCode: "299R5792O49769YV",
    modelName: "Position Allocation",
    prevData: {
      page: "CurrentAllocation",
      sidebar: "CurrentAllocation",
      page1: "CurrentAllocation",
      active: "CurrentAllocation",
    },
    nextScreen: "CurrentAllocation",
  },
  {
    elementCode: "576A1509D48583HY",
    modelName: "Candidate Profile",
    prevData: {
      page: "candidateList",
      sidebar: "candidateList",
      page1: "candidateList",
      active: "candidateList",
    },
    nextScreen: "candidateList",
  },
  {
    elementCode: "578N9655V35665UM",
    modelName: "Candidate Allocation",
    prevData: {
      page: "currentCandidateAllocation",
      sidebar: "currentCandidateAllocation",
      page1: "currentCandidateAllocation",
      active: "currentCandidateAllocation",
    },
    nextScreen: "currentCandidateAllocation",
  },
  {
    elementCode: "565H3739A99377GB",
    modelName: "My Interview",
    prevData: {
      page: "positionALL",
      sidebar: "myInterviewList",
      page1: "myInterviewList",
      active: "myInterviewList",
    },
    nextScreen: "myInterviewList",
  },
  {
    elementCode: "770A7037J19810OF",
    modelName: "Offer Release Process",
    prevData: {
      page: "myCandidateFinalStages",
      sidebar: "myCandidateFinalStages",
      page1: "myCandidateFinalStages",
      active: "myCandidateFinalStages",
    },
    nextScreen: "myCandidateFinalStages",
  },
  {
    elementCode: "763B2361D74864JC",
    modelName: "Joining Process",
    prevData: {
      page: "positionALL",
      sidebar: "myTasks",
      page1: "myTasks",
      active: "myTasks",
    },
    nextScreen: "myTasks",
  },
  {
    elementCode: "592M9844Q58797QM",
    modelName: "Client Management",
    prevData: {
      page: "positionALL",
      sidebar: "clients",
      page1: "clients",
      active: "clients",
    },
    nextScreen: "clients",
  },
  {
    elementCode: "299P9132U25202EU",
    modelName: "Client Deployement",
    prevData: {
      page: "positionALL",
      sidebar: "candidateAllocation(Client)",
      page1: "candidateAllocation(Client)",
      active: "candidateAllocation(Client)",
    },
    nextScreen: "candidateAllocation(Client)",
  },
  {
    elementCode: "740D2340W72779WJ",
    modelName: "Candidate Journey",
    prevData: {
      page: "positionALL",
      sidebar: "reportCandidateList",
      page1: "reportCandidateList",
      active: "reportCandidateList",
    },
    nextScreen: "reportCandidateList",
  },
  {
    elementCode: "168U1973P29187BA",
    modelName: "Duplicity Check",
    prevData: {
      page: "positionALL",
      sidebar: "duplicityCheck",
      page1: "duplicityCheck",
      active: "duplicityCheck",
    },
    nextScreen: "duplicityCheck",
  },
  {
    elementCode: "461K3755B98401FO",
    modelName: "Report",
    prevData: {
      page: "positionALL",
      sidebar: "report",
      page1: "report",
      active: "report",
    },
    nextScreen: "report",
  },
  {
    elementCode: "772E4109H62475FQ",
    modelName: "BGV",
    prevData: {
      page: "BGV",
      sidebar: "BGV",
      page1: "BGV",
      active: "BGV",
    },
    nextScreen: "BGV",
  },
];

// check if link is valid
export function validateLink(link) {
  return link?.startsWith("http");
}

// short the name of month
export const getShortMonth = (month) => {
  if (month === "January") return "Jan";

  if (month === "February") return "Feb";

  if (month === "March") return "Mar";

  if (month === "April") return "Apr";

  if (month === "May") return "May";

  if (month === "June") return "Jun";

  if (month === "July") return "Jul";

  if (month === "August") return "Aug";

  if (month === "September") return "Sep";

  if (month === "October") return "Oct";

  if (month === "November") return "Nov";

  if (month === "December") return "Dec";
};

// short the year name
export const getShortYear = (year) => {
  const arr = year.split("-");

  const firstYear = arr[0]?.toString()?.slice(2);
  const secondYear = arr[1]?.toString();

  return `${firstYear}-${secondYear}`;
};

// input box and date onChange
export const onChangeHandler = (formData, setFormData, e, label, type) => {
  if (type === "input") {
    setFormData({
      ...formData,
      [label]: e.target.value,
    });
  }
  if (type === "date") {
    setFormData({
      ...formData,
      [label]: e,
    });
  }
};

// show error message
export const showErrMsg = (field, message, style, validation) => {
  return (
    <p
      className={validation && !field ? style.errorText : style.errorTextHidden}
    >
      {message}
    </p>
  );
};

// pagination handle
export const handlePageChange = (filter, setFilter, status) => {
  if (status === "next") {
    setFilter((prev) => ({ ...prev, page: filter.page + 1 }));
  } else if (status === "prev") {
    setFilter((prev) => ({ ...prev, page: filter.page - 1 }));
  }
};

// add ... in value
export const renderValue = (text, length) => {
  if (text) {
    if (text?.length <= length) {
      return text;
    } else {
      return text?.substring(0, length) + "...";
    }
  } else {
    return "-";
  }
};

// show hover name
export const renderTitle = (text, length) => {
  if (text) {
    if (text?.length > length) {
      return text;
    }
  } else {
    return "-";
  }
};

// ----------------------------------------- access codes ------------------------------------------------------------ //

//ATS access code
export const accessCode = {
  create_position: "598W7129O14365ME",
  position_download: "967V5198U11205EP",
  add_notes: "322Z7614K75172SH",
  edit: "300Q6349H25959ZF",
  delete: "510G5552Y26014JE",
  allocate: "414B6428E40279SR",
  view_change_history: "412H1326O88355NV",
  mark_as_inactive: "122H7558S38832MP",
  jd_share: "162G7361B71269PD",
  employee_allocate: "853W9920V16743TO",
  de_allocate: "138P4742X75963QN",
  create_candidate: "311A3736P93283YR",
  position_management_edit: "340I8831G64992WR",
  position_management_delete: "333N6221E20837XA",
  download: "618G7278W55965OU",
  recruiter_change: "386H2497O10909OU",
  drop_candidate: "453N4329X32021AL",
  candidate_profile_add_notes: "810F1758M75824IU",
  ready_for_client_share: "145T1394D72281GL",
  mark_as_offer_release: "983I7609O43090LJ",
  interview: "929D3900V67726EC",
  offers: "668I9964J83657YW",
  Candidate_allocation: "130O6390N70929AY",
  Candidate_allocate: "419Y3285E18090GS",
  my_interview_drop_candidate: "219I4263E10602LD",
  mark_for_offer_release: "337M5170C18987HM",
  cancel_for_offer_release: "337M5170C18987HM",
  Schedule_Interview: "243Q4955U94338RH",
  create_offer: "462B3241Z99826CX",
  offer_release_drop_candidate: "484G3398R19953YI",
  candidate_history: "910L5206Z31243YS",
  joining_drop_candidate: "519C6376A98890NT",
  create_task: "534C1621S44947YB",
  candidate_joined: "156K5655D34667PU",
  create_client: "441H3820O92998BO",
  client_edit: "907N8249R77856OG",
  client_delete: "253W9368J58860RL",
  allocation: "636E8627P12952AG",
  client_drop_candidate: "794E2052S44735FZ",
  add_next_stage: "933Z8520Y15023TI",
  candidate_profile: "786B9448P63691DR",
  candidate_Journey_interviews: "237H6006I57732CP",
  candidate_journey_offers: "794Q7564H81515GB",
  BGV: "727N8816Z93352PW",
  pipeline_report: "912U8189F91201ZJ",
  sourcing_report: "637J6785G76259YL",
  position_health_report: "183V2326M42458TF",
  detailed_sourcing_report: "471A8828L31552RL",
  position_report: "597B3947E10635GF",
  sourcing_performance_report: "323P4142B10875UT",
  closure_report: "357H3604O27039CA",
  sourcing_vs_share: "984K2206A19931IG",
  resource_availability: "921L4658X54541CC",
  available_position: "838P8540I32109YD",
  deployment_report: "263S6322J81969NL",
  partner_pipeline_report: "816M6642I93223FE",
  position_management_menu: "277Z4054P81578MD",
  position_allocation_menu: "299R5792O49769YV",
  candidate_profile_menu: "576A1509D48583HY",
  candidate_allocation_menu: "578N9655V35665UM",
  my_interview_menu: "565H3739A99377GB",
  my_interviewers_menu: "861V5833X15737ZK",
  offer_release_process_menu: "770A7037J19810OF",
  joining_process_menu: "763B2361D74864JC",
  client_management_menu: "592M9844Q58797QM",
  client_deployement_menu: "299P9132U25202EU",
  candidate_journey_menu: "740D2340W72779WJ",
  duplicity_check_menu: "168U1973P29187BA",
  report_menu: "461K3755B98401FO",
  bgv_menu: "772E4109H62475FQ",
  resorce_mapping: "237G4924K96068SI",
  position_mapping: "877J2127Y71967CN",
  offer_approve: "987H9750C70070IK",
  offer_edit: "829O3966H41160XP",
  offer_Mark_as_Offer_Released: "774P7112X86484SK",
  offer_offer_decline: "769G3848K90273YF",
  offer_Offer_Accept: "335J8480B20911DK",
  offer_revoke: "162I1566I15708UL",
  offer_cancel: "990Z9487Y53304GV",
  send_for_review: "557I5366D47792RW",
  clientFollowUp: "958B5818L74488TP"
};

// UMS Access Code
export const umsAccessCode = {
  download: "831G4046O63956PI",
  create_user: "735X2426C79580OT",
  edit_user: "390E4134N65220SC",
  delete_user: "260M7138H85298XS",
  mark_as_exit_planned: "932G6473G97166SA",
  assign_lead: "255N3596D32541SO",
  mark_as_in_active: "456I9338I50133YO",
  mark_as_active: "222G2268P90855SE",
  mark_as_resign: "240Z9429N54499XX",
  mark_as_un_resigned: "113C4526O88006UO",
  add_confirmation: "452O6174M97742EZ",
  mark_as_retain: "300M1248Q45630KF",
  assign_client: "254J7590P15558WD",
  assign_team: "739Y9284Y41685EU",
  dashboard_menu: "434H8646C25932CG",
  users_menu: "393X8917P19515GI",
  report_menu: "828R5115E26334AR",
  exit_employee_list_menu: "188X8900V74709UQ",
  confirmation_list_menu: "561K1732O62173ED",
  new_joinees_menu: "442P1051Z78907ER",
  exited_employee_menu: "146U4966I46451DR",
};

// Task Tracker
export const taskTrackerAccessCode = {
  create_Task_MyTask: "601L5180A98941SQ",
  done_Review_MyTask: "675G1796R61729BJ",
  close_Task_MyTask: "178F8488S19781UE",
  view_Task_History_MyTask: "802G6136R26862OY",
  submit_CreateTask: "324Y2487G56646QH",
  create_Task_TaskAssigned: "936Y1661T59050GI",
  close_Task_TaskAssigned: "459K6101J27384RN",
  view_Task_History_TaskAssigned: "797C3656N42392DY",
  add_Comment_TaskAssigned: "217M8090W96585LC",
  delete_TaskAssigned: "339Q7507X74744CK",
  edit_TaskAssigned: "256T6057U55389FK",
  view_Task_History_CloseTask: "559T9723V24495DR",
  delete_CloseTask: "203A4539D62345EU",
  edit_CloseTask: "830J4796G72914WX",
  add_Comment_CloseTask: "996J7932X22350EQ",
  my_task_menu: "940Y4644C56265AH",
  create_task_menu: "237R1656D48590OO",
  task_assigned_by_me_menu: "443O1996O46217IS",
  close_task_menu: "257Z6346F18806YY",
  my_recurring_task_menu: "893L1428B08745IF"
};

// epr
export const EprAccessCode = {
  add_new_review: "187K9388D25849MD",
  download_button: "754K8226W69512IS",
  delete: "132O9727A32533XW",
  edit_feedback: "644I9265N98924CQ",
  done: "724I8523V57904GY",
  cancel: "551Z8584P31454HK",
  submit_feedback: "916E7135F77924UZ",
};

// rms
export const rmsAccessCode = {
  client_card: "363L6697J60514XA",
  project_card: "888E3891P76437UB",
  resource_offshore_card: "607W1897R42015VY",
  resource_onsite_card: "332F7372U73428LN",
  pool_card: "404X3708O54866TZ",
  partial_pool_card: "776M2344C85796EZ",
  blocked_resources: "698Q8594F64846RN",
  create_client: "676M7200U22972HO",
  mark_as_inactive: "830S7924W47899FM",
  edit_client: "783Q6430U59025TV",
  create_project: "662W7341B79618IS",
  allocate_resource_offshore: "421M2357S99247UW",
  ddd_task_offshore: "680V1489V14574GG",
  add_comment_offshore: "522X7028B82625XQ",
  offshore_history: "595B5341H63857IO",
  edit_offshore: "265O9784Q14125LR",
  allocate_resource_onsite: "647Z6688V42453JE",
  add_task_onsite: "491D3562K47446VR",
  add_comment_onsite: "786G9021I55008LA",
  onsite_history: "627B4745D43473NJ",
  edit_onsite: "947V2697H77980SM",
  allocate_resource_pool: "407V3322G25040OA",
  add_task_pool: "566O7846L39741TD",
  add_comment_pool: "989Z4755S75231UO",
  pool_history: "440E3367Y32770CJ",
  edit_pool: "767Z6360L14631DQ",
  partial_pool: "459D7129K24810SY",
  allocate_resource_partial_pool: "283M5357T85111LG",
  edit: "787Q2383C28439RM",
  total_active_deployment_report: "440K6012E18910QJ",
  total_deployment_from_innovationM: "961P3744H16722ST",
  total_deployment_from_partner: "533G8224U82568CQ",
  weekly_monthly_deployed_report: "495W4459I47206LP",
  weekly_monthly_ramp_down_report: "820D4727S78139MZ",
  weekly_monthly_notifications_resignation_report: "246H5337O32738UT",
  expected_client_to_be_informed_weekly_report: "493J7980V18910DN",
  sideBar_dashboard: "688X6305V67555PN",
  sideBar_clients: "403G6957H52630CQ",
  sideBar_project: "305L2214M37736FC",
  sideBar_offshore: "764G1527Q39090MT",
  sideBar_onsite: "884A8886X14194LX",
  sideBar_pool: "791A1924P18164OK",
  sideBar_partial_pool: "526D2762E38821LS",
  sideBar_report: "843I4408K33511WZ",
  sideBar_onsite_report: "217Y5121H56458PQ",
  delete_client: "414H7926Y18231NW",
  mark_as_hold: "973I7036J73460TA",
  mark_as_closed: "170C5010M35108NX",
  edit_project: "137S5058J63538SQ",
  add_application: "192N7340C87424QC",
  blocked_resources: "104O4022H36291UG",
  upcoming_ram_down_report: "407G7322A31497ML",
  offshore_action_column : "599Z3099S78311PH",
  onsite_action_column : "564D4010A29665OR",
  pool_action_column : "520V4449I79287AX",
  resource_by_skill : "629R7900O57412YJ",
  final_stages : "481T4829K24635TK",
  dashboard_finalstages_candidate_card : "293H8361D51698SL",
  pool_resource_view_add_location : "435F1618S41522WQ",
  pool_resource_view : "722W7872I16412FD",
  offshore_salary_range_column : "268V6775Y77225QO",
  pool_salary_range_column : "180H9960P56345PN",
};

// cc
export const clientContractCode = {
  ExpiringContracts: "431T1897C29875AZ",
  ClientContracts: "636U9989D65947TX",
  CreateContract: "521O1646F10077CT",
  Done: "563E3187E54525KM",
  Edit: "758T1996W67589JB",
};

//project costing and margin 
export const projectCostingAndMarginAccessCode = {
  allProjects_dashboard: "889H2727U45647XN",
  addSOWValue: "217S5132M85944PN",
  invoices_dashboard: "655T1193D36022FK",
  create_invoice: "440M6168H56348IK",
  edit_invoice: "481Y1657X40289PO",
  delete_invoice: "912E6052P74851AX",
  actual_project_cost: "487O9651H83481EG",
  planned_project_cost: "155N4954X79354MO",
  project_cost_breakup: "950B5360V14088DT",
  overheads_dashboard: "255D3944N83540SG",
  resourceMargins: "262H6524P27483BA"
}

// Job Applicant
export const jobApplicantAccessCode = {
  createJobApplicant: "661W5518R23479QF",
  myScreenShortListAndRejectFilter: "670L7850D12714DJ",
  recruiterAndLeadSearchScreen: "442H1517V95267VQ",
};

export const decrypted_value = (location, name) => {
  const searchParams = new URLSearchParams(location);
  const encryptedId = searchParams?.get(name);
  const decrypted_id = CryptoJS?.AES?.decrypt(
    encryptedId,
    process.env.REACT_APP_ENCRYPTION_KEY?.toString()
  )?.toString(CryptoJS?.enc?.Utf8);

  return decrypted_id;
};

export const encrypted_value = (name) => {
  const enc = encodeURIComponent(
    CryptoJS.AES.encrypt(
      name?.toString(),
      process.env.REACT_APP_ENCRYPTION_KEY?.toString()
    )?.toString()
  );

  return enc;
};
