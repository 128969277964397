import TaskTracker from "../Pages/taskTracker/tasktracker.jsx";
import CreateTask from "../Pages/taskTracker/createTask/CreateTask.js";
import ClosedTaskByMe from "../Pages/taskTracker/ClosedTaskByMe/ClosedTaskByMe.js";
import TaskAssignedByMe from "../Pages/taskTracker/taskAssignedByMe/TaskAssignedByMe.js";
import EditTask from "../Pages/taskTracker/EditTask/EditTask.js";
import EditRecurringTask from "../Pages/taskTracker/EditRecurringTask/EditTask.js"
import { GetAllGroupList } from "../Pages/taskTracker/Groups/groupList/index.js";
import MyTaskDetails from "../Pages/taskTracker/myTasks/component/MyTaskDetails.js";
import Groups from "../Pages/taskTracker/Groups/index.js";
import TaskClosedByMeDetails from "../Pages/taskTracker/ClosedTaskByMe/component/TaskClosedByMeDetails.js";
import ViewTask from "../Pages/taskTracker/ViewTask/ViewTask.js";
import TaskAssignedByMeDetails from "../Pages/taskTracker/taskAssignedByMe/component/TaskAssignedByMeDetails.js";
import TaskHistory from "../Pages/taskTracker/TaskHistory.js";
import RecurringTaskHistory from "../Pages/taskTracker/RecurringTaskHistory.js";

import EditComments from "../Pages/taskTracker/EditComments/EditComments.js";
import RecurringEditComments from "../Pages/taskTracker/EditRecurringComments/EditComments.js"
import MyRecurringTasks from "../Pages/taskTracker/RecurringTask/myTasks.js";
import RecurringTaskDetails from "../Pages/taskTracker/taskAssignedByMe/component/RecurringTaskDetails.js"
import RecurringTaskDetailsToMe from "../Pages/taskTracker/taskAssignedByMe/component/RecurringTaskDetailsForAssignedToMe.js"
import CreateRecurringTask from "../Pages/taskTracker/createRecurringTask/CreateTask.js"
import CreateTaskForGroup from "../Pages/taskTracker/createTask/component/CreateTaskForGroups.js";
import ManageMembersForGroup from "../Pages/taskTracker/Groups/manageMembers/ManageMembersForGroup.js";
import TaskAssignedInSpace from "../Pages/taskTracker/taskAssignedByMe/component/TaskAssignedInSpace.js";

// function TaskTrackerRoute() {
//   return (
//     <Route>
//       <Route
//         exct
//         path="/taskTracker/TaskAssignedByMeComponent"
//         component={TaskAssignedByMe}
//       />
//       <Route
//         exct
//         path="/taskTracker/ClosedByMeComponent"
//         component={ClosedTaskByMe}
//       />
//       <Route exact path="/taskTracker/createTask" component={CreateTask} />
//       <Route exact path="/taskTracker" component={TaskTracker} />
//       <Route exact path="/EditTask" component={EditTask} />

//       <Route exact path="/viewTask" component={ViewTask} />

//       <Route
//         exct
//         path="/taskAssignedByMeDetails"
//         component={TaskAssignedByMeDetails}
//       />
//       <Route
//         exct
//         path="/MyTaskDetailsDetails"
//         component={TaskAssignedByMeDetails}
//       />
//       <Route
//         exct
//         path="/TaskClosedByMeDetails"
//         component={TaskClosedByMeDetails}
//       />
//       <Route exct path="/MyTaskDetails" component={MyTaskDetails} />
//       <Route exct path="/taskHistory" component={TaskHistory} />

//       <Route exct path="/EditComments" component={EditComments} />
//     </Route>
//   );
// }
const TaskTrackerRoute = [
  {
    path: "/taskTracker/TaskAssignedByMe",
    component: TaskAssignedByMe
  },
  {
    path: "/taskTracker/ClosedByMe",
    component: ClosedTaskByMe
  },
  {
    path: "/taskTracker/CreateTask",
    component: CreateTask
  },

  {
    path: "/taskTracker/MyTasks",
    component: TaskTracker
  },
  {
    path: "*/*/ViewTask",
    component: ViewTask
  },
  // {
  //   path: "/taskTracker/RecurringTasks/ViewTask",
  //   component: ViewTask
  // },
  // {
  //   path: "/taskTracker/TaskAssignedByMe/ViewTask",
  //   component: ViewTask
  // },
  // {
  //   path: "/taskTracker/ClosedByMe/ViewTask",
  //   component: ViewTask
  // },
  {
    path: "/EditTask",
    component: EditTask
  },
  {
    path: "*/*/EditTask",
    component: CreateTask
  },
  // {
  //   path: "/viewTask",
  //   component: ViewTask
  // },
  {
    path: "/taskAssignedByMeDetails",
    component: TaskAssignedByMeDetails
  },
  {
    path: "/MyTaskDetailsDetails",
    component: TaskAssignedByMeDetails
  },
  {
    path: "/TaskClosedByMeDetails",
    component: TaskClosedByMeDetails
  },
  {
    path: "/MyTaskDetails",
    component: MyTaskDetails
  },
  {
    path: "*/taskHistory",
    component: TaskHistory
  },
  {
    path: "/recurringTask/EditComments",
    component: RecurringEditComments
  },
  {
    path: "*/*/EditComments",
    component: EditComments
  },
  {
    path: "/taskTracker/Spaces",
    component: Groups
  },
  {
    path: "/taskTracker/Spaces/SpaceDetails",
    component: GetAllGroupList
  },
  {
    path: "/taskTracker/Spaces/SpaceDetails/CreateTask",
    component: CreateTask,
  },
  // {
  //   path: "/taskTracker/Spaces/SpaceDetails/ViewTask",
  //   component: ViewTask
  // },
  {
    path: "/taskTracker/RecurringTasks",
    component: MyRecurringTasks
  },
  {
    path: "*/recurringTaskCreate",
    component: CreateRecurringTask
  },
  {
    path: "/taskTracker/recurringTaskDetails",
    component: RecurringTaskDetails
  },
  {
    path: "/taskTracker/editRecurringTask",
    component: EditRecurringTask
  },
  {
    path: "*/*/EditRecurringTask",
    component: EditRecurringTask
  },
  {
    path: "/taskTracker/recurringTaskDetailsAssignedToMe",
    component: RecurringTaskDetailsToMe
  },
  {
    path: "*/recurringTaskHistory",
    component: RecurringTaskHistory
  },
  {
    path: "/GroupWiseList/CreateTask",
    component: CreateTaskForGroup
  },
  {
    path: "*/*/ManageMembers",
    component: ManageMembersForGroup
  },
  {
    path: "/GroupWiseList/viewSpaceTask",
    component: TaskAssignedInSpace
  }
];

// Now `routes` contains an array of JSON objects with "path" and "component" keys.


export default TaskTrackerRoute;
