import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import style from "./CommentHistory.module.css";
import AddComment from "../AddComment/AddComment";
import deleteImg from "../../../../../assets/icons8-delete-24.png";
import editImg from "../../../../../assets/icons8-edit-30.png";
import { getAllPositionComments, updatePositionComment, getTagsByPosition, getAllTags, 
addTag, deletePositionTag, addTagsToPosition } from "../../../../../Utils/axios/api";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { notifyError, notifySuccess } from "../../../utils/notify";
import { employeeId } from "../../../../../Utils/constants";
import { Modal } from "react-responsive-modal";
import CreatableSelect from "react-select/creatable";
export const customSearch = {
  control: (base, { isDisabled }) => ({
    // margin: "0 7px",
    ...base,
    borderRadius: "5px",
    padding: "0 20px 0 10px",
    border: "1px solid #00000061",
    cursor: "pointer",
    backgroundColor: isDisabled ? "#e0dcdc" : "#f5f6fa",
    marginRight: "6px"
  }),
  placeholder: () => ({
    // display: "none",
    marginTop: "-30px",
    color: "hsl(0, 0%, 5%);",
    fontSize: "15px"
  }),

  //  option color on hover green
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  // dropdown width
  menu: (provided) => ({
    ...provided,
    position: "absolute",
    width: "90%",
    marginLeft: "0.6rem",
    zIndex: "999",
    maxHeight: "300px",
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
};

const getCommentForPosition = (positionId) => {
  const existingComments = JSON.parse(localStorage.getItem('unsavedComments')) || [];
  const commentData = existingComments.find(c => c.positionId === positionId);
  return commentData ? commentData.comment : '';
};

// export function TagHistory({
//   candidateId,
//   setShowCommentModal,
//   positionId,
//   positionName,
//   myClientName,
//   getCommentForPosition,
//   positionCodee
// }) {
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [selectedTag, setSelectedTag] = useState(null);

//   const { data:tagData, isLoading:tagLoading, refetch: refetchTag } = useQuery(
//     "getTagsByPosition",
//     () => getTagsByPosition(positionId),
//     { refetchOnWindowFocus: false, retry: false }
//   );

//   const { data: getAllTagData } = useQuery(["getAllTags"], getAllTags, { refetchOnWindowFocus: false });
//   const getAllTagOption = getAllTagData?.map((e) => ({
//     value: e.id,
//     label: e.name,
//   }));

//   const updateCandidateCommentMutation = useMutation(updatePositionComment, {
//     onMutate: () => { },
//     onSuccess: () => {
//       notifySuccess("Tag Deleted Successfully");
//       refetchTag();
//     },
//     onError: (err) => { console.error(err) }
//   });

//   const deleteComment = () => {
//     if (selectedTag) {
//       updateCandidateCommentMutation.mutate({
//         commentId: selectedTag.commentId,
//         empId: selectedTag.employeeId,
//       });
//       setShowDeleteModal(false);
//     }
//   };

//   const cancelDelete = () => {
//     setShowDeleteModal(false);
//     setSelectedTag(null);
//   };

//   const handleDeleteTag = (tag) => {
//     setShowDeleteModal(true);
//     setSelectedTag(tag);
//   };

//   return (
//     <div>
//       <DeleteConfirmationModel
//         showDeleteModal={showDeleteModal}
//         deleteComment={deleteComment}
//         cancelDelete={cancelDelete}
//       />
//       <div className={style.description_box1}>
//         <div className={style.span2}>
//           {positionName === undefined && myClientName === undefined ? (
//             <p></p>
//           ) : positionName && myClientName ? (
//             <p>{positionCodee} - {positionName} ({myClientName})</p>
//           ) : positionCodee ? (
//             <p>{positionCodee} - {positionName}</p>
//           ) : (<p>{positionName}</p>)}
//         </div>
//         <div className={style.maindivContent} style={{width:"25rem", margin: "auto", marginTop: "10px"}}>
//           <CreatableSelect 
//             placeholder = "Select Tag..."
//             options={getAllTagOption}
//           />
//         </div>
//         <div
//           className={style.original_table}
//           style={{
//             backgroundColor: "white",
//             minHeight: "3rem",
//             marginTop: "7px",
//           }}
//         >
//           <>
//             <Table borderless size="sm" style={{ fontSize: "14.5px" }}>
//               <thead
//                 style={{
//                   backgroundColor: "#F0F0F0",
//                   position: "sticky",
//                   top: "0px",
//                 }}
//               >
//                 <tr>
//                   <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
//                   <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
//                   <th style={{ padding: "10px" }}>Tag</th>
//                   <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {!tagLoading && tagData?.length > 0 ? (
//                   tagData.map((tag, index) => (
//                     <tr key={index}>
//                       <td style={{ paddingLeft: "10px", minWidth: "4rem" }}>
//                         {tag?.created
//                           ? moment(tag.created).year() === moment().year()
//                             ? moment(tag.created).format("DD MMM")
//                             : moment(tag.created).format("DD MMM YY")
//                           : null}
//                       </td>
//                       <td style={{ paddingLeft: "10px", minWidth: "170px", width: "170px" }}>
//                         {tag?.employeeName}
//                       </td>
//                       <td style={{ paddingLeft: "10px", whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}>
//                         {tag?.tag}
//                       </td>
//                       {tag.employeeId === employeeId ? (
//                         <td style={{ paddingLeft: "10px", minWidth: "75px" }}>
//                           <img
//                             src={deleteImg}
//                             alt="delete"
//                             height="22px"
//                             width="35px"
//                             style={{ cursor: "pointer", paddingLeft: "10px" }}
//                             title="Delete tag"
//                             onClick={() => handleDeleteTag(tag)}
//                             onKeyDown={() => handleDeleteTag(tag)}
//                             tabIndex={0}
//                           />
//                         </td>
//                       ) : (
//                         <td style={{ paddingLeft: "10px", minWidth: "75px" }}></td>
//                       )}
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="4" style={{ fontWeight: "600", fontSize: "14px", padding: "10px", textAlign: "center" }}>
//                       No Tags Found
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </Table>
//           </>
//         </div>
//       </div>
//     </div>
//   );
// }

export function TagHistory({
  candidateId,
  setShowTagModal,
  positionId,
  positionName,
  myClientName,
  positionCodee,
}) {
  const [showDeleteModalTag, setShowDeleteModalTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const { data: tagData, isLoading: tagLoading, refetch: refetchTag } = useQuery(
    "getTagsByPosition",
    () => getTagsByPosition(positionId),
    { refetchOnWindowFocus: false, retry: false }
  );

  const { data: getAllTagData, refetch: refetchAllTags } = useQuery(
    ["getAllTags"],
    getAllTags,
    { refetchOnWindowFocus: false }
  );
  const getAllTagOption = getAllTagData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const updateCandidateTagMutation = useMutation(deletePositionTag, {
    onMutate: () => {},
    onSuccess: () => {
      notifySuccess("Tag Deleted Successfully");
      refetchTag();
    },
    onError: (err) => {
      console.error(err);
      notifyError("Something went wrong");
    },
  });

  const createTagMutation = useMutation(addTag, {
    onMutate: () => {},
    onSuccess: (newTag) => {
      notifySuccess("Tag created successfully!");
      refetchAllTags();
    },
    onError: (err) => {
      console.error(err);
      notifyError("Something went wrong");
    },
  });
  const handleCreateTag = (inputValue) => {
    createTagMutation.mutate({ tagName: inputValue }); // Directly use mutation with tagName
  };

  const deleteComment = () => {
    if (selectedTag) {
      console.log("selectedTag", selectedTag);
      updateCandidateTagMutation.mutate({
        tagId: selectedTag.id,
        empId: selectedTag.createdByUserId,
      });
      setShowDeleteModalTag(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModalTag(false);
    setSelectedTag(null);
  };

  const handleDeleteTag = (tag) => {
    setShowDeleteModalTag(true);
    setSelectedTag(tag);
  };

  const addTagsForPositionMutation = useMutation(addTagsToPosition, {
    onMutate: () => {},
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        notifySuccess("Tag Added Successfully");
      } else {
        notifyError(res.responseData.message);
      }
      refetchTag();
    },
    onError: (err) => {
      notifyError("Something went wrong");
    },
  });


  return (
    <div>
      <DeleteConfirmationModelTag
        showDeleteModal={showDeleteModalTag}
        deleteComment={deleteComment}
        cancelDelete={cancelDelete}
      />
      <div className={style.description_box1}>
        <div className={style.span2}>
          {positionName === undefined && myClientName === undefined ? (
            <p></p>
          ) : positionName && myClientName ? (
            <p>
              {positionCodee} - {positionName} ({myClientName})
            </p>
          ) : positionCodee ? (
            <p>
              {positionCodee} - {positionName}
            </p>
          ) : (
            <p>{positionName}</p>
          )}
        </div>
        <div
          className={style.maindivContent}
          style={{ width: "25rem", margin: "auto", marginTop: "10px" }}
        >
          <CreatableSelect
            style={customSearch}
            placeholder="Select Tag..."
            options={getAllTagOption}
            onCreateOption={handleCreateTag} 
            onChange={(selectedOption) => {
              if (selectedOption) {
                addTagsForPositionMutation.mutate({
                  positionId: positionId,
                  tagCreatedBy: employeeId, 
                  tagId: selectedOption.value,
                });
              }
            }}
          />
        </div>
        <div
          className={style.original_table}
          style={{
            backgroundColor: "white",
            minHeight: "3rem",
            marginTop: "7px",
            maxHeight: "23rem",
            overflowY: "auto",
          }}
        >
          <>
            <Table borderless size="sm" style={{ fontSize: "14.5px",  }}>
              <thead
                style={{
                  backgroundColor: "#F0F0F0",
                  position: "sticky",
                  top: "0px",
                }}
              >
                <tr>
                  <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
                  <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                  <th style={{ padding: "10px" }}>Tag</th>
                  <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {!tagLoading && tagData?.length > 0 ? (
                  tagData.map((tag, index) => (
                    <tr key={index}>
                      <td style={{ paddingLeft: "10px", minWidth: "4rem" }}>
                        {tag?.createdOn
                          ? moment(tag.createdOn).year() === moment().year()
                            ? moment(tag.createdOn).format("DD MMM")
                            : moment(tag.createdOn).format("DD MMM YY")
                          : null}
                      </td>
                      <td
                        style={{
                          paddingLeft: "10px",
                          minWidth: "170px",
                          width: "170px",
                        }}
                      >
                        {tag?.createdBy}
                      </td>
                      <td
                        style={{
                          paddingLeft: "10px",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "anywhere",
                        }}
                      >
                        {tag?.tagName}
                      </td>
                      {tag.createdByUserId === employeeId ? (
                        <td
                          style={{
                            paddingLeft: "10px",
                            minWidth: "75px",
                          }}
                        >
                          <div className={style.buttonDelete} title="Delete"
                            onClick={() => handleDeleteTag(tag)}
                            onKeyDown={() => handleDeleteTag(tag)}
                            tabIndex={0}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 69 14"
                              className={`${style.svgIcon} ${style.binTop}`}
                            >
                              <g clip-path="url(#clip0_35_24)">
                                <path
                                  fill="black"
                                  d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_35_24">
                                  <rect fill="white" height="14" width="69"></rect>
                                </clipPath>
                              </defs>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 69 57"
                              className={`${style.svgIcon} ${style.binBottom}`}
                            >
                              <g clip-path="url(#clip0_35_22)">
                                <path
                                  fill="black"
                                  d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_35_22">
                                  <rect fill="white" height="57" width="69"></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          {/* <img
                            src={deleteImg}
                            alt="delete"
                            height="22px"
                            width="35px"
                            style={{ cursor: "pointer", paddingLeft: "10px" }}
                            title="Delete tag"
                            onClick={() => handleDeleteTag(tag)}
                            onKeyDown={() => handleDeleteTag(tag)}
                            tabIndex={0}
                          /> */}
                        </td>
                      ) : (
                        <td style={{ paddingLeft: "10px", minWidth: "75px" }}></td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      style={{
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "10px",
                        textAlign: "center",
                      }}
                    >
                      No Tags Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        </div>
      </div>
    </div>
  );
}

function CommentHistory({
  candidateId,
  setShowCommentModal,
  closeModal,
  positionId,
  positionName,
  myClientName,
  handleAddComment,
  getCommentForPosition,
  positionCodee
}) {
  const [obj, setObj] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [showAddComment, setShowAddComment] = useState(false);

  const { data: CommentData, isLoading: commentLoading, refetch: refetchComments } = useQuery(
    "getAllPositionComments",
    () => getAllPositionComments(positionId),
    { refetchOnWindowFocus: false, retry: false }
  );

  const updateCandidateCommentMutation = useMutation(updatePositionComment, {
    onMutate: () => { },
    onSuccess: () => {
      notifySuccess("Comment Deleted Successfully");
      refetchComments();
    },
    onError: (err) => { }
  });

  useEffect(() => {
    const unsavedComment = getCommentForPosition(positionId);
    if (unsavedComment) {
      setShowAddComment(true);
    }
  }, [positionId]);

  const handleAddComments = () => {
    setShowAddComment(true);
  };

  const handleEditComment = (comment) => {
    setShowAddComment(true);
    setObj(comment);
  };

  const deleteComment = () => {
    if (selectedComment) {
      updateCandidateCommentMutation.mutate({
        commentId: selectedComment.commentId,
        empId: selectedComment.employeeId,
      });
      setShowDeleteModal(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedComment(null);
  };

  const handleDeleteComment = (comment) => {
    setShowDeleteModal(true);
    setSelectedComment(comment);
  };

  console.log("positionCodee -- ", positionCodee)

  return (
    <div>
      <DeleteConfirmationModel
        showDeleteModal={showDeleteModal}
        deleteComment={deleteComment}
        cancelDelete={cancelDelete}
      />
      <div className={style.description_box1}>
        <div className={style.span2}>
          {positionName === undefined && myClientName === undefined ? (
            <p></p>
          ) : positionName && myClientName ? (
            <p>{positionCodee} - {positionName} ({myClientName})</p>
          ) : positionCodee ? (
            <p>{positionCodee} - {positionName}</p>
          ) : (<p>{positionName}</p>)}
        </div>
        <div className={style.maindivContent}></div>
        <div
          className={style.original_table}
          style={{
            backgroundColor: "white",
            minHeight: "3rem",
            marginTop: "7px",
          }}
        >
          <>
            <Table borderless size="sm" style={{ fontSize: "14.5px" }}>
              <thead
                style={{
                  backgroundColor: "#F0F0F0",
                  position: "sticky",
                  top: "0px",
                }}
              >
                <tr>
                  <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
                  <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                  <th style={{ padding: "10px" }}>Comment</th>
                  <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {!commentLoading && CommentData?.length > 0 ? (
                  CommentData.map((comment, index) => (
                    <tr key={index}>
                      <td style={{ paddingLeft: "10px", minWidth: "4rem" }}>
                        {comment?.created
                          ? moment(comment.created).year() === moment().year()
                            ? moment(comment.created).format("DD MMM")
                            : moment(comment.created).format("DD MMM YYYY")
                          : null}
                      </td>
                      <td style={{ paddingLeft: "10px", minWidth: "170px", width: "170px" }}>
                        {comment?.employeeName}
                      </td>
                      <td style={{ paddingLeft: "10px", whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}>
                        {comment?.comment}
                      </td>
                      {comment.employeeId === employeeId ? (
                        <td style={{ paddingLeft: "10px", minWidth: "75px" }}>
                          <img
                            src={editImg}
                            height="20px"
                            width="20px"
                            alt="edit"
                            style={{ cursor: "pointer" }}
                            title="Edit Comment"
                            onClick={() => handleEditComment(comment)}
                            onKeyDown={() => handleEditComment(comment)}
                            tabIndex={0}
                          />
                          <img
                            src={deleteImg}
                            alt="delete"
                            height="22px"
                            width="35px"
                            style={{ cursor: "pointer", paddingLeft: "10px" }}
                            title="Delete Comment"
                            onClick={() => handleDeleteComment(comment)}
                            onKeyDown={() => handleDeleteComment(comment)}
                            tabIndex={0}
                          />
                        </td>
                      ) : (
                        <td style={{ paddingLeft: "10px", minWidth: "75px" }}></td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{ padding: "10px", textAlign: "center" }}>
                      No Comments Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {!commentLoading && CommentData?.length === 0 && (
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                No Comments Found
              </p>
            )}
          </>

        </div>
        <div className={style.addButton}>
          {!showAddComment && (
            <button className={style.add_btn} onClick={handleAddComments}>
              Add Comment
            </button>
          )}
        </div>
      </div>
      {showAddComment && (
        <AddComment
          candidateId={candidateId}
          showAddComment={showAddComment}
          setShowAddComment={setShowAddComment}
          obj={obj}
          setObj={setObj}
          setShowCommentModal={setShowCommentModal}
          positionId={positionId}
          closeModal={closeModal}
          handleAddComment={handleAddComment}
          getCommentForPosition={getCommentForPosition}
        />
      )}
    </div>
  );
}

const DeleteConfirmationModel = ({
  showDeleteModal,
  cancelDelete,
  deleteComment,
}) => {
  return (
    <Modal
      open={showDeleteModal}
      onClose={() => {
        showDeleteModal(false);
      }}
      showCloseIcon={false}
      center
    >
      <div className={style.deleteModal}>
        <div> Do you want to delete this comment ?</div>
        <div className={style.btnRow}>
          <button className={style.yes_btn} onClick={deleteComment}>
            Yes
          </button>
          <button className={style.no_btn} onClick={cancelDelete}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

const DeleteConfirmationModelTag = ({
  showDeleteModal,
  cancelDelete,
  deleteComment,
}) => {
  return (
    <Modal
      open={showDeleteModal}
      onClose={() => {
        showDeleteModal(false);
      }}
      showCloseIcon={false}
      center
    >
      <div className={style.deleteModal}>
        <div> Do you want to delete this tag ?</div>
        <div className={style.btnRow}>
          <button className={style.yes_btn} onClick={deleteComment}>
            Yes
          </button>
          <button className={style.no_btn} onClick={cancelDelete}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CommentHistory;
